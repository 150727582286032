import axios from "axios";
import { User, UserSettingsDTO } from "../../types/User";

const SERVICES_URL = window.MLBBest.envVariables.SERVICES_URL;
const API_URL = `${SERVICES_URL}/mlbauth/api`;

export const getUserInfo = async (): Promise<User> => {
  return (await axios.get(`${API_URL}/userInfo`)).data;
};

export const getUserSettings = async (): Promise<UserSettingsDTO> => {
  return (await axios.get(`${API_URL}/userSettings`)).data;
};

export const postUser = async (user: User): Promise<void> => {
  return await axios.post(`${API_URL}/users/${user.id}`, user);
};
