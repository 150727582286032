import { AuthState, OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { getUserInfo } from "../api/mlbauth/UserInfoClient";
import config from "../config";
import { User } from "../types/User";
import { RoleConstants } from "../constants/RoleConstants";
import { PermissionConstants } from "../constants/PermissionConstants";
import RouteConstants from "../constants/RouteConstants";

interface UserInfo extends User {
  accessToken: { accessToken: string };
  idToken: any;
}

type UserContext = {
  userInfo: UserInfo;
  loggedIn: boolean;
  login?: (authState: AuthState) => void;
  logout: () => void;
  permissionError: boolean;
  loginFunction?: SetStateAction<void>;
  setPermissionError?: Dispatch<SetStateAction<boolean>>;
  setLoginFunction?: Dispatch<SetStateAction<void>>;
  getUserId?: () => number;
  isAdmin: () => boolean;
  isAuthenticator: () => boolean;
  hasPermission: (permission: PermissionConstants) => boolean;
  getDefaultRoute: () => RouteConstants;
};

const initialState: UserState = {
  userInfo: {
    accessToken: { accessToken: "" },
    idToken: { claims: { email: "" } },
    id: -1,
    trackviaAuthenticatorId: -1,
    username: "",
    firstName: "",
    lastName: "",
    fullName: "",
    role: "",
    orgId: -1,
    permissions: [],
  },
  loggedIn: false,
};

const UserContext = createContext<UserContext>({
  userInfo: initialState.userInfo,
  loggedIn: false,
  permissionError: false,
  logout: () => {
    console.log("logout");
  },
  isAdmin: (): boolean => false,
  isAuthenticator: (): boolean => false,
  hasPermission: (permission: PermissionConstants): boolean => false,
  getDefaultRoute: () => RouteConstants.BASE,
});

interface UserState {
  userInfo: UserInfo;
  loggedIn: boolean;
}

const oktaAuth = new OktaAuth(config.oidc);

type UserProviderProps = {
  children: React.ReactNode;
};

const UserProvider = ({ children }: UserProviderProps) => {
  const [userState, setUserState] = useState<UserState>(initialState);
  const [loggedIn, setLoggedIn] = useState<boolean>(initialState.loggedIn);
  const [permissionError, setPermissionError] = useState<boolean>(false);
  const [loginFunction, setLoginFunction] = useState();
  const { authState } = useOktaAuth();

  const login = async (authState: AuthState) => {
    setLoggedIn(true);
    //Fill in with application-specific user info call
    const userInfo = await getUserInfo();

    if (userInfo && authState.accessToken && authState.idToken) {
      setUserState({
        userInfo: { accessToken: authState.accessToken, idToken: authState.idToken, ...userInfo },
        loggedIn: true,
      });
    }
  };

  const logout = (): void => {
    localStorage.clear();
    setUserState(initialState);
    setLoggedIn(false);
  };

  const getUserId = (): number => {
    return userState.userInfo.id;
  };

  const isAdmin = (): boolean => {
    return userState.userInfo.role === RoleConstants.SUPER_ADMIN;
  };

  const isAuthenticator = (): boolean => {
    return hasPermission(PermissionConstants.AUTHENTICATOR);
  };

  const hasPermission = (permission: PermissionConstants): boolean => {
    return userState.userInfo.permissions.includes(permission);
  };

  const getDefaultRoute = () => {
    if (!loggedIn) return RouteConstants.BASE;
    if (isAdmin()) return RouteConstants.EYEBALL_GAMES;

    if (hasPermission(PermissionConstants.AUTHENTICATOR)) {
      return RouteConstants.EYEBALL_GAMES;
    } else if (hasPermission(PermissionConstants.INVENTORY)) {
      return RouteConstants.INVENTORY;
    } else if (hasPermission(PermissionConstants.MILESTONES)) {
      return RouteConstants.MILESTONES;
    } else {
      return RouteConstants.BASE;
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      // when the user becomes authenticated, call onLogin() to populate AuthContext's user info
      login({ accessToken: authState.accessToken, idToken: authState.idToken });
    }
  }, [authState]);

  return (
    <UserContext.Provider
      value={{
        userInfo: userState.userInfo,
        loggedIn,
        login,
        logout,
        permissionError,
        setPermissionError,
        loginFunction,
        setLoginFunction,
        getUserId,
        isAdmin,
        isAuthenticator,
        hasPermission,
        getDefaultRoute,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): UserContext => {
  const userContext = useContext<UserContext>(UserContext);
  if (userContext === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return userContext;
};

export { UserContext, UserProvider, oktaAuth, useUser, UserInfo };
