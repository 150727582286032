export const EYEBALL_IFRAME_ID = "eyeball-mobile-app-iframe";

export enum EyeballEntityType {
  ITEM = "Item",
  OTHER_ITEM = "OtherItem",
  COVERT_JERSEY = "CovertJersey",
  BARCODE_HOLOGRAM_ASSOCIATION = "BarcodeHologramAssociation",
}

export enum ItemType {
  GAME_USED_BALL = "Game-Used Ball",
  GAME_USED_BAT = "Game-used Bat",
  GAME_USED_BROKEN_BAT = "Game-used Broken Bat",
  GAME_USED_JERSEY = "Game-used Jersey",
}

export type EyeballPlayState = "undefined" | "lost" | "authenticated";

export const EyeballPlayState = {
  UNDEFINED: "undefined" as EyeballPlayState,
  LOST: "lost" as EyeballPlayState,
  AUTHENTICATED: "authenticated" as EyeballPlayState,
};
