import React, { createContext, useContext, useEffect, useState } from "react";
import { EyeballDiamondVideo } from "../types/Eyeball";

interface DiamondVideoPlayerState {
  videos: Map<string, EyeballDiamondVideo>;
  selectedVideo: EyeballDiamondVideo;
  playId: string;
  isPlaying: boolean;
}

interface DiamondVideoPlayerStateUpdate {
  videos?: Map<string, EyeballDiamondVideo>;
  selectedVideo?: EyeballDiamondVideo;
  playId?: string;
  isPlaying?: boolean;
}

type DiamondVideoPlayerContext = {
  state: DiamondVideoPlayerState;
  clearState: () => void;
  getVideos: () => Map<string, EyeballDiamondVideo>;
  getSelectedVideo: () => EyeballDiamondVideo;
  getSelectedVideoAngle: () => string;
  getVideoAngles: () => string[];
  getPlayId: () => string;
  getIsPlaying: () => boolean;
  setVideos: (videos: Map<string, EyeballDiamondVideo>) => void;
  setSelectedVideo: (video: EyeballDiamondVideo) => void;
  setSelectedVideoAngle: (angle: string) => void;
  setPlayId: (playId: string) => void;
  setIsPlaying: (isPlaying: boolean) => void;
  updateDiamondVideoPlayerState: (update: DiamondVideoPlayerStateUpdate) => void;
};

const initialState: DiamondVideoPlayerState = {
  videos: new Map(),
  selectedVideo: { angle: "", playStartOffset: -1, url: "" },
  playId: "",
  isPlaying: false,
};

const DiamondVideoPlayerContext = createContext<DiamondVideoPlayerContext>({
  state: {
    videos: initialState.videos,
    selectedVideo: initialState.selectedVideo,
    playId: initialState.playId,
    isPlaying: initialState.isPlaying,
  },
  clearState: () => {
    console.log("clear state");
  },
  getVideos: () => initialState.videos,
  getSelectedVideo: () => initialState.selectedVideo,
  getVideoAngles: () => [],
  getSelectedVideoAngle: () => "",
  getPlayId: () => "",
  getIsPlaying: () => false,
  setVideos: () => {
    console.log("setVideos");
  },
  setSelectedVideo: () => {
    console.log("setSelectedVideo");
  },
  setSelectedVideoAngle: () => {
    console.log("setSelectedVideoAngle");
  },
  setPlayId: () => {
    console.log("setPlayId");
  },
  setIsPlaying: () => {
    console.log("setIsPlaying");
  },
  updateDiamondVideoPlayerState: () => {
    console.log("updateDiamondVideoPlayerState");
  },
});

type DiamondVideoPlayerProviderProps = {
  children: React.ReactNode;
};

const DiamondVideoPlayerProvider = ({ children }: DiamondVideoPlayerProviderProps) => {
  const [diamondVideoPlayerState, setDiamondVideoPlayerState] = useState<DiamondVideoPlayerState>(initialState);

  const clearState = () => {
    updateDiamondVideoPlayerState(initialState);
  };

  const getVideos = (): Map<string, EyeballDiamondVideo> => {
    return diamondVideoPlayerState.videos;
  };

  const setVideos = (videos: Map<string, EyeballDiamondVideo>) => {
    setDiamondVideoPlayerState({ ...diamondVideoPlayerState, videos: videos });
  };

  const getVideoAngles = (): string[] => {
    return [...diamondVideoPlayerState.videos.keys()];
  };

  const getSelectedVideo = (): EyeballDiamondVideo => {
    return diamondVideoPlayerState.selectedVideo;
  };

  const setSelectedVideo = (video: EyeballDiamondVideo) => {
    setDiamondVideoPlayerState({ ...diamondVideoPlayerState, selectedVideo: video });
  };

  const getSelectedVideoAngle = (): string => {
    return getSelectedVideo().angle;
  };

  const setSelectedVideoAngle = (angle: string) => {
    const selectedAngleVideo = diamondVideoPlayerState.videos.get(angle);
    if (selectedAngleVideo) {
      setSelectedVideo(selectedAngleVideo);
    }
  };

  const getPlayId = (): string => {
    return diamondVideoPlayerState.playId;
  };

  const setPlayId = (playId: string) => {
    setDiamondVideoPlayerState({ ...diamondVideoPlayerState, playId: playId });
  };

  const getIsPlaying = (): boolean => {
    return diamondVideoPlayerState.isPlaying;
  };

  const setIsPlaying = (isPlaying: boolean) => {
    setDiamondVideoPlayerState({ ...diamondVideoPlayerState, isPlaying: isPlaying });
  };

  const updateDiamondVideoPlayerState = (update: DiamondVideoPlayerStateUpdate) => {
    setDiamondVideoPlayerState({ ...diamondVideoPlayerState, ...update });
  };

  useEffect(() => {
    if (diamondVideoPlayerState.selectedVideo === initialState.selectedVideo) {
      setSelectedVideoAngle("CF");
    } else if (!Array.from(diamondVideoPlayerState.videos.values()).includes(diamondVideoPlayerState.selectedVideo)) {
      setSelectedVideoAngle(diamondVideoPlayerState.selectedVideo.angle);
    }
  }, [diamondVideoPlayerState.videos, diamondVideoPlayerState.selectedVideo]);

  return (
    <DiamondVideoPlayerContext.Provider
      value={{
        state: diamondVideoPlayerState,
        clearState,
        getVideos,
        getVideoAngles,
        getSelectedVideo,
        getSelectedVideoAngle,
        getPlayId,
        getIsPlaying,
        setVideos,
        setSelectedVideo,
        setSelectedVideoAngle,
        setPlayId,
        setIsPlaying,
        updateDiamondVideoPlayerState,
      }}
    >
      {children}
    </DiamondVideoPlayerContext.Provider>
  );
};

const useDiamondVideoPlayer = (): DiamondVideoPlayerContext => {
  const diamondVideoPlayerContext = useContext<DiamondVideoPlayerContext>(DiamondVideoPlayerContext);
  if (diamondVideoPlayerContext === undefined) {
    throw new Error(`useDiamondVideoPlayer must be used within a DiamondVideoPlayerProvider`);
  }
  return diamondVideoPlayerContext;
};

export { DiamondVideoPlayerContext, DiamondVideoPlayerProvider, useDiamondVideoPlayer };
