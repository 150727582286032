import { ColorType, Cool, ThemeType } from "best-common-react";

const BASE_FONT_REM_SIZE = 1;
const scaleFontSize = (scaleFactor: number): string => `${BASE_FONT_REM_SIZE * scaleFactor}rem`;

type MlbAuthColorType = ColorType & {
  error: string;
  success: string;
};

export const MlbAuthColors: MlbAuthColorType = {
  ...Cool.colors,
  error: "#B30C3E",
  success: "#00A651",
};

export const MlbAuthTheme: ThemeType = {
  ...Cool,
  colors: MlbAuthColors,
  background: "#F5F7FA",
  accordion: {
    ...Cool.accordion,
    header: {
      ...Cool.accordion.header,
      background: "#fff",
      padding: 0,
    },
    body: {
      ...Cool.accordion.body,
      background: "#f5f7fa",
    },
  },
  navigation: {
    ...Cool.navigation,
    horizontal: {
      ...Cool.navigation.horizontal,
      leftLogo: {
        ...Cool.navigation.horizontal.leftLogo,
        height: 114,
        width: 54,
        margin: "0 0 0 0.75rem",
      },
    },
  },
  button: {
    ...Cool.button,
    disabled: {
      ...Cool.button.disabled,
      color: Cool.colors["dark-grey"],
    },
  },
  toggleButtons: {
    ...Cool.toggleButtons,
    padding: "4px",
  },
  typography: {
    ...Cool.typography,
    h1: {
      ...Cool.typography.h1,
      size: scaleFontSize(2),
      weight: 300,
    },
    h2: {
      ...Cool.typography.h2,
      size: scaleFontSize(1.5),
      weight: 300,
    },
    h3: {
      ...Cool.typography.h3,
      size: scaleFontSize(1.25),
      weight: 300,
    },
    h4: {
      ...Cool.typography.h4,
      size: scaleFontSize(1),
      weight: 300,
    },
    h5: {
      ...Cool.typography.h5,
      size: scaleFontSize(0.75),
      weight: 300,
    },
    h6: {
      ...Cool.typography.h6,
      size: scaleFontSize(0.5),
      weight: 300,
    },
  },
};
