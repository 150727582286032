import { DataTableDataType } from "best-common-react";
import { EyeballEntityType, EyeballPlayState } from "../constants/EyeballConstants";

export type SessionAuthenticator = {
  sessionAuthenticatorId: number;
  authenticatorId: number;
  authenticatorFirstName: string;
  authenticatorLastName: string;
  isSessionClosed: boolean;
};

export interface Session extends DataTableDataType {
  sessionName: string;
  gamePk: number;
  sessionId: number;
  awayTeamId: number;
  homeTeamId: number;
  awayTeamName: string;
  homeTeamName: string;
  gameDateTime: string;
  doubleHeader: boolean;
  gameNumber: number;
  numHolograms: number;
  sessionAuthenticators: SessionAuthenticator[];
}

export type EyeballAdminSessionPayload = {
  adminSession: Session;
  eyeballSessionAuthenticatorInfos: Record<number, EyeballSessionAuthenticatorInfo>;
};

export type AuthenticatorItemReview = {
  sessionAuthenticator: SessionAuthenticator;
  review: EyeballItemReview;
};

export type AuthenticatorOtherItemReview = {
  sessionAuthenticator: SessionAuthenticator;
  review: EyeballOtherItemReview;
};

export interface EyeballSessionAuthenticatorInfo extends DataTableDataType {
  sessionAuthenticatorId: number;
  authenticatorId: number;
  authenticatorFirstName: string;
  authenticatorLastName: string;
  itemReviews: EyeballItemReview[];
  otherItemReviews: EyeballOtherItemReview[];
  numItems: number;
  numItemsAuthenticated: number;
  numOtherItemsAuthenticated: number;
  totalAuthenticated: number;
  areAuthenticatedItemsWithMissingPlaysPresent: boolean;
  areAuthenticatedItemsWithFlagPresent: boolean;
  areAuthenticatedBallsWithPitchOfInterestNotLastPresent: boolean;
  reviewStatus: string;
  isPrePublished: boolean;
  isAdminReviewComplete: boolean;
  areAllItemsPublished: boolean;
  hologramRolls: EyeballHologramRoll[];
  destroyedHolograms: EyeballDestroyedHologram[];
  closeSession: boolean;
}

export interface EyeballReview {
  itemId: number;
  entityType: EyeballEntityType;
  itemType: string;
  hologram?: string;
  secondHologram?: string;
  firstBatPiece?: string;
  secondBatPiece?: string;
  prevAuth?: boolean;
  sessionAuthenticatorId: number;
  flagged: boolean;
  flaggedDate?: string;
  description?: string;
  comment?: string;
  itemOrder: number;
}

export interface EyeballItemReview extends EyeballReview {
  isIncompleteBat: boolean;
  matchedAtBatPlayGroups: MatchedAtBatPlayGroup[];
  // NOTE: these lists intentionally contain null
  eyeballPlays: (EyeballPlay | null)[];
  mlbPlays: (MlbPlay | null)[];
  arePlaysMismatched: boolean;
  isAnyPitchOfInterestNotLastForBall: boolean;
}

export interface EyeballOtherItemReview extends EyeballReview {
  appId: string;
  itemType: string;
  itemCategory: string;
  otherItemType: string;
  barcode?: string;
  prevAuth?: boolean;
  // TODO: do we need to add the new teamJerseyColorId field here?
  jerseyType?: string;
  jerseyColor?: string;
  autographers?: string;
  description?: string;
  playerId?: number;
  playerName?: string;
}

export type MatchedAtBatPlayGroup = {
  atBatIndex: number | null;
  atBatResult: string | null;
  matchedAtBatPlays: MatchedAtBatPlay[];
};

export type MatchedAtBatPlay = {
  eyeballPlay: EyeballPlay | null;
  mlbPlay: MlbPlay | null;
  nonPitchEventMismatch: boolean;
};

export type EyeballPlay = {
  id: number;
  itemType: string;
  itemCategory: string;
  inning: number;
  halfInning: string;
  playerId: number;
  playerName: string;
  playerJerseyNumber: number;
  hologram: string;
  secondHologram: string;
  state: EyeballPlayState;
  atBatIndex: number;
  playEventIndex: number;
  pitchIndex: number;
  eventType: string;
  nonPitchEvent: boolean;
};

export type MlbPlay = {
  playId: string;
  gamePk: number;
  playIndex: PlayIndex;
  eyeballPlayIndex: number;
  pitchNumber: number | null;
  batterId: number;
  batterName: string;
  pitcherId: number;
  pitcherName: string;
  inning: number;
  halfInning: string;
  postPlayCount: PlayCount;
  hitData: HitData;
  playResult: string;
  atBatResult: string;
  nonPitchEvent: boolean;
  nonPitchEventInfo?: NonPitchEventInfo;
  pitchOfInterest: boolean;
  pitchViolation: boolean;
};

export type PlayIndex = {
  atBatIndex: number;
  playIndex: number;
};

export type PlayCount = {
  balls: number;
  strikes: number;
  outs: number;
};

export type HitData = {
  exitVelocity: number;
  launchAngle: number;
  distance: number;
};

export type NonPitchEventInfo = {
  actionPlayId: string;
  description: string;
  event: string;
  eventType: string;
  playIndex: PlayIndex;
};

export type EyeballHologramRoll = {
  id: number;
  sessionAuthenticatorId: number;
  prefix: string;
  number: number;
  startingHologramId: number;
  endingHologramId: number;
  nextHologramId: number;
};

export type EyeballDestroyedHologram = {
  rollId: number;
  hologramId: number;
};

export type EyeballDiamondVideo = {
  angle: string;
  playStartOffset: number;
  url: string;
};

export type EyeballDiamondVideos = {
  videos: Map<string, EyeballDiamondVideo>;
};

export enum PlayEventTypeName {
  PITCH = "PITCH",
  PICKOFF_ATTEMPT = "PICKOFF_ATTEMPT",
  PITCH_VIOLATION = "PITCH_VIOLATION",
}

export type EyeballPlayType = "eyeball" | "mlb";

export const EyeballPlayType = {
  EYEBALL: "eyeball" as EyeballPlayType,
  MLB: "mlb" as EyeballPlayType,
};

export enum ItemReviewError {
  ITEM_INCOMPLETE = "itemIncomplete",
  FLAG = "flag",
  PITCH_OF_INTEREST_NOT_LAST = "pitchOfInterestNotLast",
}

export enum PostMessage {
  SYNCING = "syncing",
  REFRESH = "refresh",
}
