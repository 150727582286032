import { UserInfo } from "../contexts/UserContext";
import { EYEBALL_IFRAME_ID, EyeballEntityType, ItemType } from "../constants/EyeballConstants";

export const isGameUsedBall = (itemType: string) =>
  itemType.localeCompare(ItemType.GAME_USED_BALL, "en", { sensitivity: "base" }) === 0;

export const isGameUsedBatOrBrokenBat = (itemType: string) =>
  itemType.localeCompare(ItemType.GAME_USED_BAT, "en", { sensitivity: "base" }) === 0 ||
  itemType.localeCompare(ItemType.GAME_USED_BROKEN_BAT, "en", { sensitivity: "base" }) === 0;

enum EyeballPostMsgType {
  AUTH = "auth",
  EDIT = "edit",
  RESET_MODALS = "reset_modals",
}

type IframePostMsgBody = {
  type: EyeballPostMsgType;
  authInfo: {
    accessToken: string;
    idToken: string;
    email: string;
  };
  sessionAuthenticatorId?: number;
  gamePk?: number;
  scrollToEntityType?: EyeballEntityType;
  scrollToIndex?: number;
};

export const sendIframeAuthMsg = (userInfo: UserInfo) => {
  const postMsgBody: IframePostMsgBody = {
    type: EyeballPostMsgType.AUTH,
    authInfo: {
      accessToken: userInfo.accessToken.accessToken,
      idToken: userInfo.idToken.idToken,
      email: userInfo.username,
    },
  };

  sendIframeMsg(postMsgBody);
};

export const sendIframeEditMsg = (
  userInfo: UserInfo,
  sessionAuthenticatorId: number,
  gamePk: number,
  scrollToEntityType: EyeballEntityType,
  scrollToIndex: number,
) => {
  const postMsgBody: IframePostMsgBody = {
    type: EyeballPostMsgType.EDIT,
    authInfo: {
      accessToken: userInfo.accessToken.accessToken,
      idToken: userInfo.idToken.idToken,
      email: userInfo.username,
    },
    sessionAuthenticatorId,
    gamePk,
    scrollToEntityType,
    scrollToIndex,
  };

  sendIframeMsg(postMsgBody);
};

export const sendIframeResetModalsMsg = (userInfo: UserInfo) => {
  const postMsgBody: IframePostMsgBody = {
    type: EyeballPostMsgType.RESET_MODALS,
    authInfo: {
      accessToken: userInfo.accessToken.accessToken,
      idToken: userInfo.idToken.idToken,
      email: userInfo.username,
    },
  };

  sendIframeMsg(postMsgBody);
};

const sendIframeMsg = (postMsgBody: IframePostMsgBody) => {
  const iframe = document.getElementById(EYEBALL_IFRAME_ID) as HTMLIFrameElement | null;
  iframe?.contentWindow?.postMessage(postMsgBody, window.MLBBest.envVariables.EYEBALL_APP_URL ?? "");
};
