import React from "react";
import ReactDom from "react-dom";
import App from "./App";
import "best-common-react/lib/styles/bcr.css";
import "./index.css";
import WrapperProvider from "./WrapperProvider";

ReactDom.render(
  <WrapperProvider>
    <App />
  </WrapperProvider>,
  document.getElementById("mlb-authentication-app"),
);
