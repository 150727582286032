import { createTheme, ThemeProvider } from "@mui/material";
import { AlertProvider, AlertsFromProvider, BCR, Loading } from "best-common-react";
import React, { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { useUser } from "./contexts/UserContext";
import { MlbAuthColors, MlbAuthTheme } from "./theme/MlbAuthTheme";

const AuthenticatedApp = lazy(() => import("./AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./UnauthenticatedApp"));

const App = () => {
  const { loggedIn } = useUser();

  const theme = createTheme({
    palette: {
      primary: {
        light: MlbAuthColors.blue,
        main: MlbAuthColors.blue,
        dark: MlbAuthColors.blue,
      },
    },
    typography: {
      fontFamily: "var(--bs-font-sans-serif)",
      button: {
        textTransform: "none",
        fontWeight: 600,
      },
    },
  });

  return (
    <Suspense fallback={<Loading type="mlb" />}>
      <ThemeProvider theme={theme}>
        <BCR theme={MlbAuthTheme}>
          <AlertProvider>
            <AlertsFromProvider />
            <BrowserRouter>{loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}</BrowserRouter>
          </AlertProvider>
        </BCR>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
