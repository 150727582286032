export enum RouteConstants {
  LOGIN = "/login",
  LOGIN_CALLBACK = "/login/callback",
  EYEBALL_GAMES = "/eyeball/games",
  EYEBALL_GAME_VIEWER = "/eyeball/gameViewer/:gamePk",
  INVENTORY = "/inventory",
  INVENTORY_HOLOGRAM = "/inventory/:hologram",
  MILESTONES = "/milestones",
  SETTINGS = "/settings",
  BASE = "/",
}

export default RouteConstants;
